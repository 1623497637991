// import packages
import React, {Component, useRef, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";

//Import assets
import '../assets/styles/containers/blog-details.scss'

//Import components
import MetaTags from "../components/MetaTags";
import bg from '../assets/images/contact_bg.png'

//Import utils
import {GetSingleBlog} from "../redux/actions";
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {Link, useLocation, useParams} from "react-router-dom";
import {useEffect} from "react";
import {CopyIcon, FacebookIcon, ImgArrIcon, LinkedinIcon, TwitterIcon} from "../assets/images";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {SHARE_URL} from "../constants/constType";
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton} from "react-share";
import NewsSlider from "../components/uiElements/newsSlider/NewsSlider";
import Slider from "react-slick";

function BlogDetails(props){
    const {singleBlog,blogs,staticTexts} = props
    const location = useLocation()
    const [sliderList,setSliderList] = useState([])
    const params = useParams()
    const URL = `${SHARE_URL}${location?.pathname}`
    let sliderRef = useRef(null);

    useEffect(() => {
        props.GetSingleBlog(params.slug)
    },[params.slug, props.selectedLanguage?.code])

    useEffect(()=>{
        blogs?.itemsList.length && setSliderList( blogs?.itemsList?.filter(blog => blog?.id !== singleBlog?.id))
    },[singleBlog])

    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        autoplay:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: sliderRef,
    };

    const imgList = [singleBlog?.mediaMain?.path,singleBlog?.mediaMain?.path,singleBlog?.mediaMain?.path]

    return <div className={'blog-details-wrapper'}>
        <div className={'details-main-block'}>
            <div className={'left-block'}>
              <div className={'block-title'}>
                  {singleBlog?.title}
              </div>
                <div className={'block-description'}>
                    {singleBlog?.description}
                </div>
                <div className={'block-date'}>
                    {staticTexts?.blog_pageDetail_data_title} {moment(singleBlog?.date).format("DD.MM.YYYY")}
                </div>
            </div>
            <div className={'right-block'}>
                {
                    singleBlog?.medias?.length ? <Slider {...settings}>
                        {imgList?.length && imgList.map((item, index) => {
                            return <div className={'img-block'} key={index}>
                                <img src={generateImageMediaUrl(item)}/>
                            </div>
                        })}
                    </Slider> : <div className={'img-block'}>
                        <img src={generateImageMediaUrl(singleBlog?.mediaMain?.path)}/>
                    </div>
                }
            </div>
        </div>
        <div className={'details-info-block'}>
            <div className={'details-share-block'}>
            <CopyToClipboard text={`${URL}`}
                                 onCopy={() => {}}>
                    <p><CopyIcon/></p>
                </CopyToClipboard>
                <TwitterShareButton url={`${URL}/${params.slug}`} quote={""}>
                    <p><TwitterIcon/></p>
                </TwitterShareButton>
                <FacebookShareButton url={`${URL}/${params.slug}`} quote={""}>
                  <p><FacebookIcon/></p>
                </FacebookShareButton>
                <LinkedinShareButton url={`${URL}/${params.slug}`} title={''}>
                    <p><LinkedinIcon/></p>
                </LinkedinShareButton>
            </div>
            <div className={'text'} dangerouslySetInnerHTML={{__html: singleBlog?.details}}/>
        </div>

        <NewsSlider blog={sliderList}/>

        <Link to={'/blog'} className={'responsive-link'}>
            {staticTexts?.blog_page_all_news_title}
        </Link>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'singleBlog',
        'blogs',
        'staticTexts',
    ])
};

const mapDispatchToProps = {
    GetSingleBlog
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogDetails);
